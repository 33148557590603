import { render, staticRenderFns } from "./SSOSignUp.vue?vue&type=template&id=ee451b1a&scoped=true"
import script from "./SSOSignUp.vue?vue&type=script&lang=js"
export * from "./SSOSignUp.vue?vue&type=script&lang=js"
import style0 from "./SSOSignUp.vue?vue&type=style&index=0&id=ee451b1a&prod&lang=scss&scoped=true"
import style1 from "./SSOSignUp.vue?vue&type=style&index=1&id=ee451b1a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee451b1a",
  null
  
)

export default component.exports